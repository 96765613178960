/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {registerUser} from '../../../../api'

const initialValues = {
  name: '',
  lastname: '',
  email: '',
  password: '',
  type: '',
  phone_number: '',
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Full name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email Address is required'),
  type: Yup.string().required('Account type is required'),
  password: Yup.string().min(6, 'Minimum 6 characters').required('Password is required'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const history = useHistory()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        registerUser(values)
          .then((response) => {
            setLoading(false)
            history.push('/auth/login', {
              alert:
                'Registration was successful. An activation email has been sent for your confirmation.',
            })
            //dispatch(auth.actions.login(accessToken))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Something went wrong. Please try again.')
          })
      }, 1000)
    },
  })

  return (
    <div
      className='h-100 d-flex bgi-no-repeat bgi-size-cover bgi-position-x-end bgi-attachment-fixed flex-fill '
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/images/register-bg.jpg')})`,
      }}
    >
      {/* begin::Content */}
      <div className=' h-100 bg-white px-lg-15 pt-md-10 w-lg-600px w-md-400px'>
        {/* begin::Logo */}
        {/* <a href='#' className='mb-12'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2-dark.svg')} className='h-45px' />
      </a> */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='p-lg-10'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            {/* begin::Heading */}
            <div className='mb-10'>
              <div className='h-100 mb-5'>
                <img
                  src='/media/logos/logo-1.png'
                  alt=''
                  className='h-100px mw-100'
                  height='100px'
                />
              </div>
              {/* begin::Title */}
              <h1 className='text-dark mb-3'>Create an Account</h1>
              {/* end::Title */}

              <div className='text-gray-400 fs-8'>
                Provide the details below to create an account. An activation email will be sent to
                you for verification after creating the account.
              </div>

              <div className='mt-5 text-gray-500 fs-7'>
                You already have an account?{' '}
                <Link to='/auth/login' className='link-primary'>
                  Sign In
                </Link>
              </div>
            </div>
            {/* end::Heading */}

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            {/* begin::Form group name */}
            <div className='row fv-row mb-7'>
              <div className='col-xl-12'>
                {/* <label className='form-label fw-bolder text-dark fs-7'>Name</label> */}
                <input
                  placeholder='Enter your full name'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('name')}
                  className={clsx('form-control form-control-lg  fs-5', {
                    'is-invalid': formik.touched.name && formik.errors.name,
                  })}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container mt-2 fs-8 text-gray-400'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Email */}
            <div className='fv-row mb-7'>
              {/* <label className='form-label fw-bolder text-dark fs-7'>Email Address</label> */}
              <input
                type='email'
                placeholder='Enter your email address'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx('form-control form-control-lg  fs-5', {
                  'is-invalid': formik.touched.email && formik.errors.email,
                })}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container mt-2 fs-8 text-gray-400'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5 position-relative'>
              {formik.values.password && (
                <img
                  src={`/media/icons/duotone/${showPassword ? 'eye-close' : 'eye'}.svg`}
                  className='h-20px opacity-50 position-absolute end-0 me-4 mt-4'
                  alt='Show/Hide Password'
                  title='Show/Hide Password'
                  role='button'
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
              {/* <label className='form-label fw-bolder text-dark fs-7'>Password</label> */}
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Choose a password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx('form-control form-control-lg  fs-5')}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container mt-2 fs-8 text-gray-400'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Password */}
            <div className='mb-10 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <div className='position-relative mb-3'>
                  {/* <label className='form-label fw-bolder text-dark fs-7'>Account Type</label> */}

                  <select className='form-select fs-5 ' {...formik.getFieldProps('type')}>
                    <option>Select Account Type</option>
                    <option value={'Investee'}>Enterprise</option>
                    <option value={'Investor'}>Fund Manager</option>
                  </select>
                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container mt-2 fs-8 text-gray-400'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.type}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Form group */}

            <div className='text-center text-gray-500 fs-8'>
              You already have an account?{' '}
              <Link to='/auth/login' className='link-primary'>
                Sign In
              </Link>
            </div>
          </form>
        </div>
        {/* end::Wrapper */}

        {/* begin::Footer */}
        <div className='pt-10 text-center text-gray-400  fs-8'>
          Copyright &copy; 2021 Bewsys Footer. All Rights Reserved.
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Content */}
    </div>
  )
}
