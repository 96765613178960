/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {forgotPassword} from '../../../../api'

const initialValues = {
  email: '',
}

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email Address is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        forgotPassword(values)
          .then((response) => {
            setLoading(false)
            history.push('/auth/login', {
              alert: 'An email has been sent to you to help reset your password.',
            })
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Something went wrong. Please try again.')
          })
      }, 1000)
    },
  })

  return (
    <div
      className='h-100 d-flex bgi-no-repeat bgi-size-cover bgi-position-x-end bgi-attachment-fixed flex-fill '
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/images/forgot-bg.jpg')})`,
      }}
    >
      {/* begin::Content */}
      <div className=' h-100 bg-white px-lg-15 pt-md-10 w-lg-600px w-md-400px'>
        {/* begin::Logo */}
        {/* <a href='#' className='mb-12'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2-dark.svg')} className='h-45px' />
      </a> */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='p-lg-10'>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
          >
            {/* begin::Heading */}
            <div className='mb-10'>
              <div className='h-100 mb-5'>
                <img
                  src='/media/logos/logo-1.png'
                  alt=''
                  className='h-100px mw-100'
                  height='100px'
                />
              </div>
              {/* begin::Title */}
              <h1 className='text-dark mb-3'>Forgot Password</h1>
              {/* end::Title */}

              <div className='text-gray-400 fs-8'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </div>

              <div className='mt-5 text-gray-500 fs-7'>
                Remembered your password?{' '}
                <Link to='/auth/login' className='link-primary'>
                  Sign In
                </Link>
              </div>
            </div>
            {/* end::Heading */}

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            {/* begin::Form group Email */}
            <div className='fv-row mb-7'>
              {/* <label className='form-label fw-bolder text-dark fs-7'>Email Address</label> */}
              <input
                type='email'
                placeholder='Enter your email address'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx('form-control form-control-lg  fs-5', {
                  'is-invalid': formik.touched.email && formik.errors.email,
                })}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container mt-2 fs-8 text-gray-400'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_up_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Form group */}

            <div className='text-center text-gray-500 fs-8'>
              Remembered your password?{' '}
              <Link to='/auth/login' className='link-primary'>
                Sign In
              </Link>
            </div>
          </form>
        </div>
        {/* end::Wrapper */}

        {/* begin::Footer */}
        <div className='pt-10 text-center text-gray-400  fs-8'>
          Copyright &copy; 2021 Bewsys Footer. All Rights Reserved.
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Content */}
    </div>
  )
}
