/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {loginUser} from '../../../../api'
import {userTemplate} from '../__mocks__/usersTableMock'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const location = useLocation<any>()

  let {alert} = location.state || {}

  if (localStorage.getItem('alert')) {
    alert = localStorage.getItem('alert')

    localStorage.removeItem('alert')
  }

  const [loading, setLoading] = useState(false)

  const [showPassword, setShowPassword] = useState(false)

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setStatus(null)
      location.state = null
      setLoading(true)
      setTimeout(() => {
        loginUser({email: values.email, password: values.password})
          .then((response) => {
            setLoading(false)

            if (response.data.access_token) {
              const user = {
                ...userTemplate,
                id: response.data.user.id,
                username: response.data.user.email,
                email: response.data.user.email,
                auth: {
                  accessToken: response.data.user.access_token,
                  refreshToken: response.data.user.access_token,
                },
                fullname: response.data.user.name,
                firstname: response.data.user.name,
                phone: response.data.user.phone_number,
                type: response.data.user.type,
                password: undefined,
              }

              localStorage.setItem('user', JSON.stringify(user))
              localStorage.setItem('access_token', response.data.access_token)
              localStorage.setItem('instances', response.data.instances)
              return dispatch(auth.actions.login(response.data.access_token as string))
            }

            setSubmitting(false)

            if (response.data.message === 'User is not active') {
              setStatus('User is currently not active. Please contact the administrator.')
              return
            }

            setStatus('The login detail is incorrect')
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <div className='h-100 d-flex  bg-white'>
      {/* begin::Content */}
      <div className='align-self-center px-lg-15 pt-md-10 w-lg-450px w-md-400px '>
        {/* begin::Logo */}
        {/* <a href='#' className='mb-12'>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-2-dark.svg')} className='h-45px' />
      </a> */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className=''>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='mb-10'>
              <div className='h-100 mb-5'>
                <img
                  src='/media/logos/logo-1.png'
                  alt=''
                  className='h-100px mw-100'
                  height='100px'
                />
              </div>
              <h1 className='text-dark mb-3'>Sign In</h1>
              <div className='text-gray-400 fs-8'>
                Please enter your login credentials to proceed.
              </div>
            </div>
            {/* begin::Heading */}

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            {alert && (
              <div className='mb-lg-15 alert alert-success'>
                <div className='alert-text font-weight-bold'>{alert}</div>
              </div>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-7 fw-bolder text-dark'>Email</label>

              <input
                placeholder='Email Address'
                {...formik.getFieldProps('email')}
                className={clsx('form-control form-control-solid')}
                type='email'
                name='email'
                autoComplete='off'
              />
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-10 position-relative'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  {/* begin::Label */}
                  <label className='form-label fw-bolder text-dark fs-7 mb-0'>Password</label>
                  {/* end::Label */}
                  {/* begin::Link */}
                  <Link
                    to='/auth/forgot-password'
                    className='link-primary fs-8'
                    style={{marginLeft: '5px'}}
                  >
                    Forgot Password ?
                  </Link>
                  {/* end::Link */}
                </div>
              </div>
              {formik.values.password && (
                <img
                  src={`/media/icons/duotone/${showPassword ? 'eye-close' : 'eye'}.svg`}
                  className='h-20px opacity-50 position-absolute end-0 me-4 mt-4'
                  alt='Show/Hide Password'
                  title='Show/Hide Password'
                  role='button'
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Enter Password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx('form-control  form-control-solid')}
              />
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary w-100 mb-5'
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid ||
                  formik.values.email === '' ||
                  formik.values.password === ''
                }
              >
                {!loading && <span className='indicator-label'>Continue</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}

            <div className='text-center text-gray-500 fs-8'>
              You don't have an account?{' '}
              <Link to='/auth/registration' className='link-primary'>
                Create an Account
              </Link>
            </div>
          </form>
        </div>
        {/* end::Wrapper */}

        {/* begin::Footer */}
        <div className='pt-10 text-gray-400  fs-8'>
          Copyright &copy; 2021 Bewsys Footer. All Rights Reserved.
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Content */}

      <div
        className='bgi-no-repeat bgi-size-cover bgi-position-x-end bgi-attachment-fixed flex-fill '
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/images/auth-bg.jpg')})`,
        }}
      >
        {' '}
      </div>
    </div>
  )
}
